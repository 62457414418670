import axios from "~/plugins/axios";

export default {
  getList(publicationStateId) {
    let params = {
      publication_state_id: publicationStateId
    };
    return axios
      .get("/ebsn/api/price-list-proposal/list", { params: params })
      .then(data => data.data)
      .catch(error => error);
  },
  getDetail(priceListProposalId) {
    let params = {
      price_list_proposal_id: priceListProposalId
    };
    return axios
      .get("/ebsn/api/price-list-proposal/view", {
        params: params
      })
      .then(data => data.data)
      .catch(error => error);
  },
  getProductPrices(priceListProposalId, page = 1, pageSize = 50) {
    let params = {
      price_list_proposal_id: priceListProposalId,
      page: page,
      page_size: pageSize
    };
    return axios
      .get("/ebsn/api/price-list-proposal/list-product-prices", {
        params: params
      })
      .then(data => data.data)
      .catch(error => error);
  },
  addProductPrice(productId, priceListProposalId, price, referencePriceId) {
    let params = {
      product_id: productId,
      price_list_proposal_id: priceListProposalId,
      price: price,
      reference_price_id: referencePriceId
    };
    return axios
      .post("/ebsn/api/price-list-proposal/add-product-price", undefined, {
        params
      })
      .then(data => data.data)
      .catch(error => error);
  },
  deleteProductPrice(priceId, priceListProposalId) {
    let params = {
      price_id: priceId,
      price_list_proposal_id: priceListProposalId
    };
    return axios
      .post("/ebsn/api/price-list-proposal/delete-product-price", undefined, {
        params
      })
      .then(data => data.data)
      .catch(error => error);
  },
  updateProductPrice(price, priceId, priceListProposalId) {
    let params = {
      price: price,
      price_id: priceId,
      price_list_proposal_id: priceListProposalId
    };
    return axios
      .post("/ebsn/api/price-list-proposal/update-product-price", undefined, {
        params
      })
      .then(data => data.data)
      .catch(error => error);
  },
  insert(
    name,
    priceListId,
    replacedPriceListId,
    fromDate,
    toDate,
    warehouseId
  ) {
    let params = {
      name: name,
      price_list_id: priceListId,
      replaced_price_list_id: replacedPriceListId,
      from_date: fromDate,
      to_date: toDate,
      warehouse_id: warehouseId
    };
    return axios
      .get("/ebsn/api/price-list-proposal/insert", {
        params: params
      })
      .then(data => data.data)
      .catch(error => error);
  },
  modify(
    priceListId,
    priceListProposalId,
    fromDate,
    toDate
  ) {
    let params = {
      price_list_id: priceListId,
      price_list_proposal_id: priceListProposalId,
      from_date: fromDate,
      to_date: toDate,
    };
    return axios
      .get("/ebsn/api/price-list-proposal/modify", {
        params: params
      })
      .then(data => data.data)
      .catch(error => error);
  },
  delete(priceListProposalId) {
    let params = {
      price_list_proposal_id: priceListProposalId
    };
    return axios
      .get("/ebsn/api/price-list-proposal/delete", {
        params: params
      })
      .then(data => data.data)
      .catch(error => error);
  },
  validateProposal(priceListProposalId) {
    let params = {
      price_list_proposal_id: priceListProposalId
    };
    return axios
      .get("/ebsn/api/price-list-proposal/confirm-proposal", {
        params: params
      })
      .then(data => data.data)
      .catch(error => error);
  },
  getSuggestedWarehouseList(priceListId) {
    let params = {
      price_list_id: priceListId
    };
    return axios
      .get("/ebsn/api/price-list-proposal/get-suggested-warehouse-list", {
        params: params
      })
      .then(data => data.data)
      .catch(error => error);
  },
  getWarehouseList() {
    return axios
      .get("/ebsn/api/price-list-proposal/warehouse-list")
      .then(data => data.data)
      .catch(error => error);
  }
};
